<script setup>
import { cn } from '@/utils'

const props = defineProps({
  class: { type: null, required: false, default: null }
})
</script>

<template>
  <tr
    :class="
      cn(
        'border-b transition-colors hover:bg-zinc-100/50 data-[state=selected]:bg-zinc-100 dark:hover:bg-zinc-800/50 dark:data-[state=selected]:bg-zinc-800',
        props.class
      )
    "
  >
    <slot />
  </tr>
</template>
