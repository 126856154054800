<template>
  <div class="flex justify-between gap-1">
    <div class="sm:flex-auto">
      <h1 class="text-xl font-semibold text-gray-900">
        {{ title }}
      </h1>

      <template v-if="description">
        <p class="mt-1 text-sm leading-5 text-gray-500">
          {{ description }}
        </p>
      </template>
    </div>

    <div class="flex justify-center items-center">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
