import { DEVICE_IP_SUBNET_MASK } from '@/constants/Devices'

/**
 * Get IP with subnet mask.
 *
 * @param {string} ip - The IP address to append the subnet mask to.
 * @returns {string} The IP address with the subnet mask appended.
 */
const getIpWithSubnetMask = (ip) => {
  return ip + '/' + DEVICE_IP_SUBNET_MASK
}

export default getIpWithSubnetMask
