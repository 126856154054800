/**
 * Initialise Paddle script.
 *
 * @TODO: Extend to include ProfitWell integration.
 *
 * @see https://developer.paddle.com/paddlejs/include-paddlejs
 * @returns {void}
 */
const initialisePaddle = () => {
  try {
    window.Paddle.Environment.set(import.meta.env.VITE_PADDLE_ENVIRONMENT || 'sandbox')

    window.Paddle.Setup({
      seller: parseInt(import.meta.env.VITE_PADDLE_VENDOR_ID)
    })
  } catch (error) {
    // @TODO: Improve error handling and monitoring.
    console.error(error)
  }
}

export default initialisePaddle
