<template>
  <Card>
    <div class="h-96 flex flex-col justify-center items-center text-center p-6">
      <div class="mx-auto h-8 w-8 text-zinc-400" aria-hidden="true">
        <slot name="icon"></slot>
      </div>

      <h3 class="mt-6 text-sm font-semibold text-zinc-900">
        {{ title }}
      </h3>

      <p v-if="description" class="mt-1 text-sm text-zinc-500">
        {{ description }}
      </p>

      <div v-if="$slots.actions" class="mt-6">
        <slot name="actions"></slot>
      </div>
    </div>
  </Card>
</template>

<script>
import { Card } from '@/components/Card'

export default {
  components: {
    Card
  },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
