/**
 * Product billing terms
 */
export const BILLING_TERMS = [
  {
    value: 'monthly',
    label: 'Monthly',
    term: 'month'
  },
  {
    value: 'annually',
    label: 'Annually',
    term: 'year'
  }
]

/**
 * Free plan billing data
 *
 * Hard-coded free plan data. Whilst some recommend that free plans should still be a $0 product, we've opted
 * to simplify the onboarding flow by not requiring the customer to enter their payment details for a free plan.
 */
export const FREE_PLAN_DATA = {
  product_id: 'free-plan',
  name: 'Free Plan',
  status: 'active',
  description:
    'Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus.',
  metadata: {
    features: 'Up to 10 devices, No credit card required',
    maxDevices: 5
  },
  prices: [
    {
      price_id: 'price-free-monthly',
      product_id: 'free-plan',
      description: 'Monthly billing',
      unit_amount: 0,
      currency: 'USD',
      billing_cycle_interval: 'month',
      billing_cycle_frequency: 1,
      trial_period_interval: 'day',
      trial_period_duration: 0,
      status: 'active'
    }
  ]
}

/**
 * Metered device billing data
 *
 * Temporarily hard-coded until Stripe supports metered billing in their product/price objects when transmitting those
 * to our webhooks. More precisely, Stripe currently only emits the unit amount for a given price when the price is a
 * licensed one, and not a metered one.
 *
 * @TODO: Update this one Paddle metered billing integration is in progress.
 */
export const METERED_BILLING_DATA = {
  productId: 'prod_J0Z2X0Z2X0Z2X0',
  priceId: 'price_1NePRbIkqv6PcKWsXmiBkD8p',
  interval: BILLING_TERMS[0].term,
  unitAmount: 0.25,
  currency: 'usd'
}
