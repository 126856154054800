import { createApp, markRaw } from 'vue'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { MotionPlugin } from '@vueuse/motion'
import VueSafeTeleport from 'vue-safe-teleport'

import App from './App.vue'
import router from './router'

import './assets/main.css'

const app = createApp(App)

// Initialize Sentry
// @see https://docs.sentry.io/platforms/javascript/guides/vue/
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS
      })
    ],
    tracesSampleRate: 1.0
  })
}

// Initialize Pinia stores.
// @see https://pinia.vuejs.org/core-concepts/
const pinia = createPinia()

// Inject router instance as a Pinia plugin.
// @see https://pinia.vuejs.org/core-concepts/plugins.html
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

app.use(pinia)
app.use(router)
app.use(MotionPlugin)
app.use(VueSafeTeleport)

app.mount('#app')
