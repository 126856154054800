<template>
  <div class="mb-8">
    <template v-if="!devicesStore.loading && !devicesStore.devices.length">
      <EmptyState title="No devices" description="Add a new device to get started.">
        <template #icon>
          <ServerIcon class="stroke-1" />
        </template>
      </EmptyState>
    </template>

    <template v-else>
      <DataTable :columns="columns" :data="devicesStore.devices" />
    </template>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { ServerIcon } from '@heroicons/vue/24/outline'

import EmptyState from '@/components/EmptyState/EmptyState.vue'

import { columns } from './columns'
import DataTable from './DataTable.vue'

import WebSocketService from '@/services/webSocketService'

import { useDevicesStore } from '@/stores/devices'

export default {
  components: {
    DataTable,
    EmptyState,
    ServerIcon
  },
  props: {
    title: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      columns,
      webSocketService: null
    }
  },
  computed: {
    ...mapStores(useDevicesStore)
  },
  async mounted() {
    await this.getDevices(true)

    this.webSocketService = new WebSocketService('list')
    this.webSocketService.initialize()
  },
  beforeUnmount() {
    if (this.webSocketService && this.webSocketService.socket) {
      this.webSocketService.disconnect(false)
    }
  },
  methods: {
    /**
     * Get devices list.
     *
     * Fetches the list of devices via the DevicesStore to be used in the component.
     *
     * @param {boolean} force - Whether to force the fetch of the devices list.
     * @returns {Promise<void>}
     */
    async getDevices(force) {
      try {
        const deviceStore = useDevicesStore()

        if (!deviceStore.devices.length || force) {
          // @TODO: Add pagination / query max results.
          await deviceStore.getDevices()
          console.log(deviceStore.devices)
        }
      } catch (error) {
        // @TODO: Improve error handling.
        console.error(error)
      }
    }
  }
}
</script>
