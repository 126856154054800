<template>
  <div class="min-h-screen bg-zinc-50">
    <template v-if="!loading">
      <Navbar :user-initials="userInitials" :sign-out-handler="signOut" />
      <router-view />
    </template>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import Navbar from '@/components/Navbar/Navbar.vue'
import initialisePaddle from '@/utils/initialisePaddle'

export default {
  name: 'App',
  components: {
    Navbar
  },
  data() {
    return {
      loading: true,
      error: null
    }
  },
  computed: {
    /**
     * Returns the user's initials based on their first and last name.
     *
     * @returns {string} The user's initials.
     */
    userInitials() {
      const authStore = useAuthStore()

      if (!authStore.user?.firstName || !authStore.user?.lastName) {
        return 'MoT'
      }

      return `${authStore.user.firstName[0]}${authStore.user.lastName[0]}`
    }
  },
  async beforeMount() {
    const authStore = useAuthStore()

    await authStore.mountAuthSession()

    if (authStore.session) {
      await authStore.loadAccountData()
    }

    this.loading = false
  },
  mounted() {
    initialisePaddle()
  },
  methods: {
    /**
     * Sign the user out.
     */
    signOut() {
      const authStore = useAuthStore()
      authStore.signOut()
    }
  }
}
</script>
