/**
 * Encodes a string into Base64Url format.
 *
 * @param {string} str - The string to encode.
 * @returns {string} The Base64Url encoded string.
 */
const base64UrlEncode = (str) => {
  let base64 = btoa(str)
  base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  return base64
}

export default base64UrlEncode
