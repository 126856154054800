/**
 * Get the base URL for the site.
 *
 * Helper to retrieve the public site URL Vercel doesn't set the correct env variables for the production environment.
 * In addition to this method, the VITE_PUBLIC_SITE_URL env variable should be set both locally as well as in Vercel for
 * the production environment exclusively.
 *
 * @param {string} path The path to append to the base URL.
 * @returns {string} The base URL for the site.
 */
const getEnvUrl = (path) => {
  let url = import.meta.env.VITE_PUBLIC_SITE_URL ?? import.meta.env.VITE_VERCEL_URL

  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`

  // Append path if provided.
  url = path ? `${url}${path}` : url

  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`

  return url
}

export default getEnvUrl
