/**
 * The database tables accessible via the Supabase SDK client.
 *
 * @constant {Object} TABLES The database table names.
 */
export const TABLES = Object.freeze({
  ACCOUNTS: 'accounts',
  ACCOUNT_USERS: 'account_users',
  DEVICES: 'devices',
  USERS: 'users',
  SUBSCRIPTIONS: 'subscription_records',
  SUBSCRIPTION_PRODUCTS: 'subscription_products',
  SUBSCRIPTION_PRICES: 'subscription_prices'
})
