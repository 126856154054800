import { BADGE_TYPES } from '@/components/Badge/Badge.vue'
import { DEVICE_STATUSES } from '@/constants/Devices'

/**
 * Get device statuss.
 *
 * If no last seen date is available and the device is not online, we consider this to be a newly added device and
 * return a pending status. The online property which is updated in the devices store by the WebSocket service in near
 * real-time.
 *
 * @param {Object} device - The device object to get the status for.
 * @returns {Object} The device status object containing the status and badge color.
 */
const getDeviceStatus = (device) => {
  if (!device.last_seen && !device.online) {
    return {
      status: DEVICE_STATUSES.PENDING,
      color: BADGE_TYPES.DEFAULT
    }
  }

  if (device.online) {
    return {
      status: DEVICE_STATUSES.ONLINE,
      color: BADGE_TYPES.SUCCESS
    }
  }

  return {
    status: DEVICE_STATUSES.OFFLINE,
    color: BADGE_TYPES.ERROR
  }
}

export default getDeviceStatus
