import logger from '../logger'
import { useAuthStore } from '../stores/auth'
import { useDevicesStore } from '../stores/devices'
import base64UrlEncode from '../utils/base64UrlEncode'
import getIpWithSubnetMask from '../utils/getIpWithSubnetMask'
import {
  WEBSOCKET_ENDPOINT,
  WEBSOCKET_ACTIONS,
  WEBSOCKET_STREAMS,
  WEBSOCKET_PROTOCOL,
  WEBSOCKET_AUTH_PREFIX
} from '../constants/WebSocket'

/**
 * WebSocketService
 *
 * @TODO: Remove unnecessary logging.
 * @TODO: Improve error handling.
 *
 * @param {WEBSOCKET_ACTIONS} action – The action to perform on the WebSocket server.
 * @param {WEBSOCKET_STREAMS} stream – The stream to subscribe to on the WebSocket server.
 * @param {string} url – The URL of the WebSocket server to connect to, defaults to the MoT WebSocket server.
 */
export default class WebSocketService {
  constructor(mode, deviceId = null) {
    this.mode = mode
    this.deviceId = deviceId
    this.authStore = useAuthStore()
    this.devicesStore = useDevicesStore()
    this.trackedDevices = []

    if (!this.mode) throw new Error('No mode provided, cannot initialize WebSocketService.')

    if (!Object.values(WEBSOCKET_STREAMS).includes(this.mode))
      throw new Error(`Invalid mode "${this.mode}" provided, cannot initialize WebSocketService.`)

    if (this.mode === WEBSOCKET_STREAMS.RELAY && !this.deviceId)
      throw new Error('No device ID provided, cannot initialize WebSocketService.')
  }

  /**
   * Initialize WebSocketService
   *
   * This method initializes the WebSocketService and establishes a WebSocket connection if deemed necessary, depending
   * on the current state and requested mode.
   *
   * @returns {void}
   */
  async initialize() {
    logger.debug(`Initializing WebSocketService in ${this.mode} mode`)

    // If the devices store has devices, connect to the WebSocket server.
    try {
      if (this.devicesStore.devices.length > 0) {
        logger.debug('Devices store has devices, establishing WebSocket connection.')
        await this.connect()
      }
    } catch (error) {
      logger.error({ msg: 'Failed to initialize WebSocket connection.', error })
      return
    }

    // Subscribe to the devices store to react to changes.
    if (this.mode === WEBSOCKET_STREAMS.LIST) this.subscribeToDevicesStore()
  }

  /**
   * Get the WebSocket instance.
   *
   * @returns {WebSocket} The WebSocket instance.
   */
  getSocket = () => {
    return this.socket
  }

  /**
   * Get WebSocket URL
   *
   * @returns {string} The WebSocket URL.
   */
  getWebsocketUrl = () => {
    const baseUrl = import.meta.env.VITE_MOT_WS_BASE_URL
    const endpoint = WEBSOCKET_ENDPOINT.replace('{accountId}', this.authStore.account.id)

    return `${baseUrl}${endpoint}`
  }

  /**
   * Connect to the WebSocket server.
   *
   * @returns {Promise<void>}
   */
  connect = () => {
    logger.debug('Initializing WebSocket connection.')

    const base64EncodedToken = base64UrlEncode(this.authStore.session.access_token)
    const authHeader = `${WEBSOCKET_AUTH_PREFIX}${base64EncodedToken}`

    return new Promise((resolve, reject) => {
      // Initialize the WebSocket connection.
      this.socket = new WebSocket(this.getWebsocketUrl(), [WEBSOCKET_PROTOCOL, authHeader])

      this.socket.onerror = (err) => {
        reject(err)
      }

      this.socket.onopen = () => {
        logger.debug('WebSocket connection established.')

        // Bind the connection and message listeners.
        this.bindErrorHandler()
        this.bindMessageHandler()
        this.emitModeInitialisationRequest()

        resolve()
      }
    })
  }

  /**
   * Disconnect from the WebSocket server.
   *
   * @param {boolean} showToast – Whether to display a toast message to the user.
   * @returns {void}
   */
  disconnect = (showToast = true) => {
    // @TODO: Replace window alert with toast message.
    if (showToast) window.alert('WebSocket connection closed.')

    this.socket.close()

    logger.debug('Closed WebSocket connection.')
  }

  /**
   * Bind error handler.
   *
   * WebSockets are weird. They don't throw errors, they emit them. This method binds an error handler to the WebSocket
   * connection to handle errors in case they occur.
   *
   * @returns {void}
   */
  bindErrorHandler = () => {
    this.socket.onerror = (error) => {
      logger.error({ msg: 'WebSocket error.', error })
    }
  }

  /**
   * Bind message handler.
   *
   * Handles incoming messages from the WebSocket server based on the requested WebSocket mode.
   *
   * @returns {void}
   */
  bindMessageHandler = () => {
    this.socket.onmessage = (event) => {
      let parsedMessage

      try {
        parsedMessage = JSON.parse(event.data)
      } catch (e) {
        logger.error({
          msg: 'Failed to parse WebSocket message.',
          error: e,
          data: event.data
        })
        return
      }

      // Abort message handling if the WS server sends a 'done' message.
      if (parsedMessage.message === 'done') {
        return
      }

      if (parsedMessage.error) {
        // @TODO: Extend error handling and logging.
        const { error } = parsedMessage
        logger.error({ msg: 'Received error message from WebSocket.', error })
        this.disconnect()
        return
      }

      // Check for errors in the response.
      if (parsedMessage.statusCode !== 200) {
        // @TODO: Extend error handling and logging.
        logger.error({
          msg: `Received message with ${parsedMessage.statusCode} status code from WebSocket.`,
          data: parsedMessage
        })
        this.disconnect()
        return
      }

      switch (this.mode) {
        case WEBSOCKET_STREAMS.LIST:
          this.handleDeviceListEvent(parsedMessage)
          break

        case WEBSOCKET_STREAMS.RELAY:
          this.handleRelayEvent(parsedMessage)
          break
      }
    }
  }

  /**
   * Bind connection handler.
   *
   * This method emits the initial messages based on the requested WebSocket mode to initialize the WebSocket
   * connection and start receiving data from the server.
   *
   * @returns {void}
   */
  emitModeInitialisationRequest = () => {
    switch (this.mode) {
      case WEBSOCKET_STREAMS.LIST:
        this.sendDeviceListRequest()
        break

      case WEBSOCKET_STREAMS.RELAY:
        this.sendRelayRequest()
        break
    }
  }

  /**
   * Subscribe to devices store.
   *
   * This method subscribes to the devices store and reacts to changes by either connecting or disconnecting from the
   * WebSocket server as well as sending device list requests when necessary.
   *
   * @returns {void}
   */
  subscribeToDevicesStore = () => {
    this.devicesStore.$subscribe((mutation, state) => {
      if (mutation.storeId !== 'devices') return

      // If no WebSocket connection exists, determine if one needs to be established.
      // This is the case when the device store has no device on load but gets populated later on.
      if (!this.socket && state.devices.length > 0) {
        this.connect()
        return
      }

      // If a Websocket connection exists, but the mutation removes all devices from the store, disconnect.
      if (this.socket && state.devices.length === 0) {
        this.disconnect(false)
        return
      }

      // Create a map of postmanId to a set of IPs for tracked devices.
      const trackedDevicesMap = this.trackedDevices.reduce((map, group) => {
        const ips = group.agents.map((agent) => agent.ip)
        map[group.postmanId] = new Set(ips)
        return map
      }, {})

      // Create a map of postmanId to a set of IPs for store devices.
      const storeDevicesMap = state.devices.reduce((map, device) => {
        const ipWithMask = getIpWithSubnetMask(device.ip)
        if (!map[device.postman_id]) {
          map[device.postman_id] = new Set()
        }
        map[device.postman_id].add(ipWithMask)
        return map
      }, {})

      // Compare the two maps to determine if there's a mismatch.
      const trackedPostmanIds = Object.keys(trackedDevicesMap)
      const storePostmanIds = Object.keys(storeDevicesMap)

      // Check if there's a difference in postmanIds or in IPs for each postmanId.
      const isMismatch =
        trackedPostmanIds.length !== storePostmanIds.length ||
        trackedPostmanIds.some((postmanId) => {
          const trackedIps = trackedDevicesMap[postmanId] || new Set()
          const storeIps = storeDevicesMap[postmanId] || new Set()

          if (trackedIps.size !== storeIps.size) return true

          for (let ip of trackedIps) {
            if (!storeIps.has(ip)) return true
          }

          return false
        })

      // If there's a mismatch, send a device list request.
      if (isMismatch) {
        logger.debug('Device list mismatch, sending device list message.')
        this.sendDeviceListRequest()
      }
    })
  }

  /**
   * Emit request for device listing.
   *
   * @returns {void}
   */
  async sendDeviceListRequest() {
    try {
      if (!this.socket) {
        await this.connect()
      }

      // Create a payload for the WS server containing the devices grouped by their Postman ID.
      // Whilst not in-use today, this allows a single account to have multiple postmen.
      const devicesGroupedByPostmanId = this.devicesStore.devices?.reduce((acc, device) => {
        const { postman_id, ip } = device
        const existingGroup = acc.find((group) => group.postmanId === postman_id)

        if (existingGroup) {
          existingGroup.agents.push({ ip: getIpWithSubnetMask(ip) })
        } else {
          acc.push({
            postmanId: postman_id,
            agents: [{ ip: getIpWithSubnetMask(ip) }]
          })
        }

        return acc
      }, [])

      const payload = {
        action: WEBSOCKET_ACTIONS.SUBSCRIBE,
        stream: WEBSOCKET_STREAMS.LIST,
        payload: devicesGroupedByPostmanId
      }

      this.socket.send(JSON.stringify(payload))

      this.trackedDevices = devicesGroupedByPostmanId

      logger.debug({ msg: 'Device list request sent.', data: payload })
    } catch (error) {
      logger.error({ msg: 'Failed to send device list request.', error })
    }
  }

  /**
   * Handle incoming device list events.
   *
   * @param {Object} message - The parsed WebSocket message object.
   * @returns {void}
   */
  handleDeviceListEvent = (message) => {
    // Check for device data in the response, if none are present we assume that no devices are online and we can safely
    // abort the message processing.
    if (!message.agents) {
      logger.debug('No devices online.')
      return
    }

    // If all checks pass, update the devices store.
    this.devicesStore.updateDevicesVisibilityStatus(message.agents)
  }

  /**
   * Emit request for device relay.
   *
   * @returns {void}
   */
  async sendRelayRequest() {
    try {
      if (!this.socket) {
        await this.connect()
      }

      // Get the device data from the devices store.
      const device = this.devicesStore.devices.find((device) => device.device_id === this.deviceId)

      if (!device) {
        throw new Error(`Device with ID ${this.deviceId} not found in devices store.`)
      }

      // const payload = {
      //   action: WEBSOCKET_ACTIONS.SUBSCRIBE,
      //   stream: WEBSOCKET_STREAMS.LIST,
      //   payload: devicesGroupedByPostmanId
      // }

      // this.socket.send(JSON.stringify(payload))

      // this.trackedDevices = devicesGroupedByPostmanId

      // logger.debug({ msg: 'Device list request sent.', data: payload })
    } catch (error) {
      logger.error({ msg: 'Failed to send device list request.', error })
    }
  }

  /**
   * Handle incoming relay events.
   *
   * @param {Object} message - The parsed WebSocket message object.
   * @returns {void}
   */
  handleRelayEvent = (message) => {
    logger.debug({
      msg: 'Received relay event, too bad though, we don’t support those yet.',
      data: message
    })
  }
}
