/**
 * Get route path.
 *
 * Util to full route path based on the passed route declaration and the route parameters.
 *
 * @param {string} route The route declaration.
 * @param {Object} params The route parameters.
 * @returns {string} The full route path.
 * @example getRoutePath('devices/:deviceId', { deviceId: 1 }) // '/devices/1'
 */
const getRoutePath = (route, params) => {
  let path = route

  if (params) {
    Object.keys(params).forEach((param) => {
      path = path.replace(`:${param}`, params[param])
    })
  }

  return path
}

export default getRoutePath
