<template>
  <div class="mb-12">
    <template v-if="!mockGroups.length">
      <EmptyState title="No groups" description="Create a new device group to get started.">
        <template #icon>
          <SquaresPlusIcon class="stroke-1" />
        </template>
      </EmptyState>
    </template>

    <template v-else>
      <Card :padding="false">
        <!-- TODO: Implement group view. -->
      </Card>
    </template>
  </div>
</template>

<script>
import { SquaresPlusIcon } from '@heroicons/vue/24/outline'

import { Card } from '@/components/Card'
import EmptyState from '@/components/EmptyState/EmptyState.vue'

export default {
  components: {
    Card,
    EmptyState,
    SquaresPlusIcon
  },
  data() {
    return {
      mockGroups: []
    }
  }
}
</script>
