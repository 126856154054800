import pino from 'pino'
import * as Sentry from '@sentry/vue'
import { AppError } from './utils/appError'

/**
 * Logger
 *
 * Custom logger implementation for the application, leveraging Pino. Whilst currently used for logging to the browser's
 * console, this will be extended in the future to log errors to NewRelic and potentially Logflare for further analysis.
 *
 * @TODO: Add transport to Loki.
 */

// Base logger configuration.
const baseLogger = pino({
  browser: {
    asObject: true,
    serialize: true
  },
  level: 'debug'
})

// Custom logger with Sentry integration.
const logger = {
  ...baseLogger,
  info: (data) => {
    baseLogger.info(data)
  },
  debug: (data) => {
    const isLocalEnv = import.meta.env.MODE === 'development'
    const debugFlag = localStorage.getItem('DEBUG')

    // Only log to the console on local dev environment or if the DEBUG flag is set.
    if (isLocalEnv || debugFlag === 'true') {
      baseLogger.debug(data)
    }
  },
  error: (data) => {
    // Log to the console.
    baseLogger.error(data)

    // Extract message and error from the data object
    const { msg, error } = data

    // Check if error is an instance of Error
    if (error instanceof Error || error instanceof AppError) {
      Sentry.captureException(error, {
        extra: { logMessage: msg, ...error }
      })
    } else {
      // Handle non-Error objects, might need customization based on what is logged
      Sentry.captureMessage(msg || 'Error', {
        level: 'error',
        extra: error || data
      })
    }
  }
}

export default logger
