<template>
  <span
    class="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium"
    :class="{
      'bg-green-100 text-green-800': type === BADGE_TYPES.SUCCESS,
      'bg-yellow-100 text-yellow-800': type === BADGE_TYPES.WARNING,
      'bg-red-100 text-red-800': type === BADGE_TYPES.ERROR,
      'bg-gray-100 text-gray-800': type === BADGE_TYPES.DEFAULT
    }"
  >
    {{ label }}
  </span>
</template>

<script>
export const BADGE_TYPES = Object.freeze({
  DEFAULT: 'default',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success'
})

export default {
  props: {
    type: {
      type: String,
      default: BADGE_TYPES.DEFAULT,
      validator: (value) => Object.values(BADGE_TYPES).includes(value)
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      BADGE_TYPES
    }
  }
}
</script>
