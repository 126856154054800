import { createClient } from '@supabase/supabase-js'

/**
 * Database Client
 *
 * Client to provide access to the database. Whilst we could use a single client for both auth and database access given
 * that both are managed/access via Supabase, those were separated in case we ever need to change the database provider.
 *
 * @see https://supabase.com/docs/
 */

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const DatabaseClient = createClient(supabaseUrl, supabaseAnonKey)

export default DatabaseClient
