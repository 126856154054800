import { FunctionsHttpError } from '@supabase/supabase-js'

import logger from '../logger'
import AuthClient from '../clients/authClient'
import { AppError } from '../utils/appError'
import { DEVICES_FUNCTION_ENDPOINT } from '../constants/FunctionEndpoints'

/**
 * Infrastructure Service
 *
 * Service class to interact with the MoT backend infrastructure services.
 */
class InfrastructureService {
  constructor(authClient = AuthClient) {
    this.authClient = authClient
  }

  /**
   * Adds a new device to the account.
   *
   * Invokes the backend serverless function to add a new device to the account and returns the response data. In case
   * of an error, an AppError object is thrown with the status code and message.
   *
   * @param {string} name – The name of the device.
   * @param {string} securityGroupId – The name of the security group to assign the device to.
   * @param {string} securityPolicyId – The name of the security policy to assign the device to.
   *
   * @returns {object} – The response data from the backend.
   * @throws {AppError} – An error object with the status code and message.
   */
  addDevice = async (name, securityGroupId, securityPolicyId) => {
    try {
      logger.debug('Invoking POST /devices function endpoint to add device.')

      // Invoke serverless function.
      const { data, error } = await this.authClient.functions.invoke(DEVICES_FUNCTION_ENDPOINT, {
        method: 'POST',
        body: {
          name,
          securityGroupId,
          securityPolicyId
        }
      })

      if (error) {
        // Check for internal errors returned by the function.
        if (error instanceof FunctionsHttpError) {
          const errorResponse = await error.context.json()
          throw new AppError(
            error.context.status,
            errorResponse.error.message,
            errorResponse.error.userMessage
          )
        }

        // For other errors types (e.g. network errors, relay errors, etc.), throw a generic error.
        throw new Error(error)
      }

      return data
    } catch (error) {
      logger.error({ msg: 'Failed to add device', error })

      if (error instanceof AppError) {
        // Handle expected application errors.
        throw error
      } else {
        // Handle unexpected errors
        throw new AppError(500, error.message)
      }
    }
  }
}

export default InfrastructureService
