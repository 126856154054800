/**
 * Application Routes
 */
export const DASHBOARD_ROUTE_PATH = '/'

export const DEVICES_OVERVIEW_ROUTE_PATH = '/devices'
export const DEVICES_SINGLE_ROUTE_PATH = '/devices/:deviceId'
export const DEVICES_SINGLE_CONTAINERS_ROUTE_PATH = '/devices/:deviceId/containers'
export const DEVICES_SINGLE_DEPLOYMENTS_ROUTE_PATH = '/devices/:deviceId/deployments'
export const DEVICES_SINGLE_LOGS_ROUTE_PATH = '/devices/:deviceId/logs'
export const DEVICES_SINGLE_TERMINAL_ROUTE_PATH = '/devices/:deviceId/terminal'

export const GROUPS_OVERVIEW_ROUTE_PATH = '/groups'
export const DEPLOYMENTS_OVERVIEW_ROUTE_PATH = '/deployments'

export const ONBOARDING_PATH = '/onboarding'

export const SETTINGS_PATH = '/settings'
export const SETTINGS_PLAN_PATH = '/settings/plan'
export const SETTINGS_CHANGE_PLAN_PATH = '/settings/plan/change'
export const SETTINGS_CHANGE_PLAN_SUCCESS_PATH = '/settings/plan/change/success'
export const SETTINGS_BILLING_PATH = '/settings/billing'
export const SETTINGS_SECURITY_PATH = '/settings/security'

export const LOGIN_ROUTE_PATH = '/login'
export const SIGNUP_ROUTE_PATH = '/signup'

export const ERROR_NOT_FOUND_ROUTE_PATH = '/404'
