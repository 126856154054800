<script setup>
import { cn } from '@/utils'

const props = defineProps({
  class: { type: null, required: false, default: null }
})
</script>

<template>
  <th
    :class="
      cn(
        'h-12 px-4 text-left align-middle font-medium text-zinc-500 [&:has([role=checkbox])]:pr-0 dark:text-zinc-400',
        props.class
      )
    "
  >
    <slot />
  </th>
</template>
