import getRoutePath from './getRoutePath'
import { DEVICES_SINGLE_ROUTE_PATH } from '@/constants/Routes'

/**
 * Get device URL.
 *
 * Wrapper for the getRoutePath utility function to be used in the component and return the single device URL.
 *
 * @param {string} id The device ID.
 * @return {string} The single device URL.
 */
const getDeviceUrl = (id) => {
  return getRoutePath(DEVICES_SINGLE_ROUTE_PATH, { deviceId: id })
}

export default getDeviceUrl
