<template>
  <Disclosure v-if="isVisible" v-slot="{ open }" as="nav" class="bg-white border border-gray-200">
    <div class="px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- 
            Mobile menu button 
          -->
          <DisclosureButton
            v-if="!isMinimal"
            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <!-- 
          Menu Content
        -->
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <img
              class="block h-8 w-auto lg:hidden"
              src="/img/mesh.svg"
              alt="Mesh of Things (MoT)"
            />
            <img
              class="hidden h-8 w-auto lg:block"
              src="/img/mesh.svg"
              alt="Mesh of Things (MoT)"
            />
          </div>
          <div v-if="!isMinimal" class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <template v-for="(item, index) in menuItems" :key="index">
              <router-link v-slot="{ href, navigate, isActive }" :to="item.path" custom>
                <a
                  :href="href"
                  class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium text-gray-500 transition-[border-color]"
                  :class="{
                    'border-black text-gray-900': isActive,
                    'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
                      !isActive
                  }"
                  @click="navigate"
                >
                  {{ item.name }}
                </a>
              </router-link>
            </template>
          </div>
        </div>

        <div
          v-if="!isMinimal"
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!-- 
            Action button
            TODO: Replace with conditional action button for adding devices or fleets.
          -->
          <button
            type="button"
            class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>

          <!-- 
            Menu: Profile dropdown 
          -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span class="sr-only">Open user menu</span>
                <span
                  class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500"
                >
                  <span class="text-xs font-medium leading-none text-white">
                    {{ userInitials }}
                  </span>
                </span>
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <template v-for="(item, index) in profileMenuItems" :key="index">
                  <router-link v-slot="{ href, navigate }" :to="item.path" custom>
                    <MenuItem v-slot="{ active }">
                      <a
                        :href="href"
                        class="transition-[background-color]"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                        @click="navigate"
                      >
                        {{ item.name }}
                      </a>
                    </MenuItem>
                  </router-link>
                </template>

                <MenuItem v-slot="{ active }">
                  <a
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    @click="signOutHandler"
                  >
                    Sign out
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <!-- 
      Mobile menu
      @TODO: Improve transitions and overall UX.
    -->
    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-4">
        <template v-for="(item, index) in menuItems" :key="index">
          <router-link v-slot="{ href, navigate, isExactActive }" :to="item.path" custom>
            <DisclosureButton
              as="a"
              :href="href"
              class="block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-gray-500"
              :class="{
                'bg-emerald-50 border-emerald-500 text-emerald-700': isExactActive,
                'border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700':
                  !isExactActive
              }"
              @click="navigate"
            >
              {{ item.name }}
            </DisclosureButton>
          </router-link>
        </template>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline'

import { NAV_PRIMARY, NAV_PROFILE } from '@/constants/Nav'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    BellIcon,
    XMarkIcon
  },
  props: {
    userInitials: {
      type: String,
      default: 'MoT'
    },
    signOutHandler: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      menuItems: NAV_PRIMARY,
      profileMenuItems: NAV_PROFILE
    }
  },
  computed: {
    /**
     * Nav visibility
     *
     * Computed property that returns a boolean value based on the meta data of the current route. This is primarly done
     * to hide the navbar on the login page.
     *
     * @returns {Boolean} Whether or not to hide the navbar.
     */
    isVisible: function () {
      return !this?.$route?.meta?.hideNavbar || false
    },

    /**
     * Minimal Nav Style
     *
     * Computed property that returns a string value based on the meta data of the current route. This is used to
     * generate a minimal navbar for the onboarding process.
     *
     * @returns {Boolean} Whether or not to use the minimal navbar.
     */
    isMinimal: function () {
      return this?.$route?.meta?.minimalNavbar || false
    }
  }
}
</script>
