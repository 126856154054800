<template>
  <LayoutContainer>
    <template v-if="authStore.loading">
      <p>Loading...</p>
    </template>

    <template v-else>
      <SectionHeading title="Devices" class="mb-2" />
      <GroupList />

      <SectionHeading title="Devices" class="mb-2" />
      <DeviceList title="Devices" />
      <!-- <p>Logged in as: {{ this.authStore.session.user.email }}</p> -->
      <!-- <pre class="text-xs">{{ this.authStore.session }}</pre> -->
    </template>
  </LayoutContainer>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth'

import LayoutContainer from '@/components/LayoutContainer/LayoutContainer.vue'
import SectionHeading from '@/components/SectionHeading/SectionHeading.vue'
import DeviceList from '@/containers/DeviceList/DeviceList.vue'
import GroupList from '@/containers/GroupList/GroupList.vue'

export default {
  components: {
    LayoutContainer,
    DeviceList,
    GroupList,
    SectionHeading
  },
  computed: {
    ...mapStores(useAuthStore)
  }
}
</script>
