<script setup>
import { cn } from '@/utils'

const props = defineProps({
  class: { type: null, required: false, default: null }
})
</script>

<template>
  <div
    :class="
      cn(
        'rounded-lg border border-zinc-200 bg-white text-zinc-950 shadow-sm dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50',
        props.class
      )
    "
  >
    <slot />
  </div>
</template>
