/**
 * AppError
 *
 * Use this class to throw errors from within the application with structured error messages and HTTP status codes.
 *
 * @param {number} status - HTTP status code
 * @param {string} message - Error message
 * @param {string} userTitle - Error title to display to the user
 * @param {string} userMessage - Error message to display to the user
 * @param {Object} metadata - Additional error metadata
 */
export class AppError extends Error {
  constructor(status, message, userTitle, userMessage, metadata = null) {
    super(message)

    this.status = status
    this.userTitle = userTitle || 'Error'
    this.userMessage = userMessage || 'An unexpected error occurred. Please try again.'
    this.metadata = metadata

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
