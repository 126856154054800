import { useAuthStore } from '../stores/auth'
import { LOGIN_ROUTE_PATH } from '../constants/Routes'

/**
 * Auth Guard Middleware.
 *
 * Verifies that the user is authenticated before allowing access to the route. If the user is not authenticated, the
 * user is redirected to the login page and the original route is stored in the query string.
 *
 * @param {Object} to The Vue-Router target route object.
 * @returns {void|Object} The route to redirect to if the user is not authenticated.
 */
export const authGuardMiddleware = async (to) => {
  const authStore = useAuthStore()
  const isAuthenticated = await authStore.isAuthenticated()

  if (!isAuthenticated) {
    return {
      path: LOGIN_ROUTE_PATH,
      query: { redirect: to.fullPath }
    }
  }
}
