import { h } from 'vue'

import Badge from '@/components/Badge/Badge.vue'

import getDeviceStatus from '@/utils/getDeviceStatus'

export const columns = [
  {
    accessorKey: 'name',
    header: () => h('div', { class: 'text-left' }, 'Name'),
    cell: ({ row }) => {
      const value = row.getValue('name')

      return h('div', {}, value)
    }
  },
  {
    accessorKey: 'ip',
    header: () => h('div', { class: 'text-left' }, 'IP Address'),
    cell: ({ row }) => {
      const value = row.getValue('ip')

      return h('div', {}, value)
    }
  },
  {
    accessorKey: 'status',
    header: () => h('div', { class: 'text-left' }, 'Status'),
    cell: ({ row }) => {
      const value = getDeviceStatus(row.original)
      return h(Badge, { type: value.color, label: value.status })
    }
  }
]
